export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText:true,
    lightTextDesc:true,
    topLine: 'Adout Bose Edutech',
    headline: 'Helps upscaling your career & Provides IT consulting Services.',
    description: 'Bose Edutechs mission is to help you keep learning in moments that matter for upscaling your career. Our platform creates a place for learners to engage in courses across business, creativity, technology and more, helping them get closer to achieving their professional goals in their career. Learning new/ relevant skills helps you build the experiences to achieve success in your career.',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../images/About.svg'),
    alt: 'Car',
    dark: true,     
    primary: true,
    darkText:false,
};

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc:false,
    topLine: 'Unlimited Premium',
    headline: 'Unlimited Courses with zero limitations',
    description: 'Get access to our exclusive app that allows you to learn unlimited skills without charged any fees.',
    buttonLabel: 'Get started',
    imgStart: true,
    img: require('../../images/programming.svg'),
    alt: 'Piggybank',
    dark: false,
    primary: false,
    darkText: true,
};

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText:false,
    lightTextDesc:false,
    topLine: 'Sign Up',
    headline: 'Get Latest updates from Bose Edutech',
    description: 'Get access to our exclusive app that allows you to learn unlimited skills with free signup and also get quotations for IT consulting Services',
    buttonLabel: 'Get started',
    imgStart: false,
    img: require('../../images/signup.svg'),
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText:true,
};