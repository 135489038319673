import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './screens';
import SigninPage from './screens/signin';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      {/* <Home/>
      <SigninPage/> */}
      <Routes>
        <Route path="/" element={<Home/>} exact />
        <Route path="/signin" element={<SigninPage/>} exact  />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
