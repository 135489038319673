import React, {useState} from 'react';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight  } from './HeroElements';
import Video from '../../videos/video1.mp4';
import { Button } from '../ButtonElements';

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    }
  return (
    <HeroContainer>
        <HeroBg>
            <VideoBg autoplay loop muted src={Video} type='video/mp4' />
        </HeroBg>
        <HeroContent>
            <HeroH1>Online Virtual Tutorials for Tech Jobs</HeroH1>
            <HeroP>
            <b>Sign up for a free class today and receive 250 Top interviews Q/A towards your next dream Job.</b>
            </HeroP>
            <HeroBtnWrapper>
                <Button to = 'signup' onMouseEnter={onHover} onMouseLeave={onHover} primary = "true" dark = "true">
                    <b>Get Started</b>{hover ? <ArrowForward/> : <ArrowRight/>}
                </Button>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection