// import { FaBars } from "react-icons/fa";
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import styled from 'styled-components';

export const Nav = styled.nav`
  background: ${({ scrollNav }) => (scrollNav ? '#8CD6FF' : 'transparent')}; 
  height: 80px;
  margin-top:-80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  position: sticky;
  top:0;
  z-index: 10;
  
  @media screen and(max-width: 960px){
    transition: 0.8s all ease;
  }
`;

export const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index:1;
  width: 100%;
  padding: 0 24px;
  max-width: 80%;
`;

export const NavLogo = styled(LinkR)`
  color: white; //change color to
  justify-content: flex-start;
  cursor: pointer;
  //font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: 700; //700
  font-size: 48px; //32px
  text-decoration: none;
`;

export const MobileIcon = styled.div`
  display: none;
  
  @media screen and (max-width: 768px){
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%,60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff; //change color to 
  }
`;

export const NavMenu = styled.ul`
  display:flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 768px){
    display:none;
  }
`;

export const NavItem = styled.li`
  height:80px;
`;

export const NavLinks = styled(LinkS)`
  color: #0C0C0C; //change color to 
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active{
    border-bottom: 3px solid #00A0FB;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px){
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  border-radius: 50px;
  background: #00A0FB; //change color to
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  &:hover{
    transition: all 0.2s ease-in-out;
    background: #fff; //change color to
    color: #010606; //change color to 

  }
`;