import React from 'react'
import Icon1 from '../../images/Service1.svg'
import Icon2 from '../../images/Service2.svg'
import Icon3 from '../../images/Service3.svg'
import { ServiceContainer, ServicesCard, ServicesH1, ServicesH2, ServicesIcon, ServicesP, ServicesWrapper } from './ServicesElements'

const Services = () => {
  return (
    <ServiceContainer id="services">
        <ServicesH1>Our Services</ServicesH1>
        <ServicesWrapper>
            <ServicesCard>
                <ServicesIcon src={Icon1}/>
                <ServicesH2>IT consulting Services</ServicesH2>
                <ServicesP>We help reduce your IT Development and increase your overall revenue. </ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon2}/>
                <ServicesH2>Virtual Courses</ServicesH2>
                <ServicesP>You can access our platform online anywhere in the world. </ServicesP>
            </ServicesCard>
            <ServicesCard>
                <ServicesIcon src={Icon3}/>
                <ServicesH2>Premium Benefits</ServicesH2>
                <ServicesP>Unlock our special membership and get in touch with Industrial experts.</ServicesP>
            </ServicesCard>
        </ServicesWrapper>
    </ServiceContainer>
  )
}

export default Services